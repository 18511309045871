.wrapperz {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containers {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#filmvideo{
    object-fit: cover;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    object-fit: cover;
    object-position: center 20%; /* Adjust the vertical position (10% down from the top) */
    z-index: -1;
}