.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#filmvid {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 80%;
    object-fit: cover;
    object-position: center 30%;
    z-index: -1;
}